.App {
  text-align: center;
  background-color: var(--background-color);
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  padding-top: 64px; // Navbar height
  overflow: hidden;
}
