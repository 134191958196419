.usernameform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 95vw;
  height: 100%;
  margin: 20px;
  background-color: var(--card-color);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--text-color);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 10px;
  }

  &__input {
    // width: 100%;
    height: 40px;
    margin: 10px 0;
    padding: 0 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
  }

  &__hint {
    font-size: 0.8rem;
    font-weight: 300;
    margin: 10px 5px;
    color: var(--text-color);
  }
}

.logout {
  margin: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__avatar {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin: 10px;
  }

  &__username {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.login {
  margin: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .usernameform {
    width: 50vw;
  }
}

@media screen and (min-width: 1024px) {
  .usernameform {
    width: 30vw;
  }
}
