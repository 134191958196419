.comment {
  background-color: var(--card-color);
  color: var(--text-color);
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  text-align: left;

  &__top {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
  }

  &__icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 4px;

    &__heart {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 4px;
      cursor: pointer;

      &__outlined {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 4px;
        cursor: pointer;

        fill: none;
        stroke-width: 1.5rem;
        stroke: var(--text-color);
      }
    }
  }

  &__author {
    font-weight: 600;
    margin-right: 10px;
    font-size: 1.25rem;
    display: inline-flex;
    align-items: center;
  }

  &__date {
    font-size: 1.125rem;
  }

  &__content {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }

  &__bottom {
    display: flex;
    align-items: flex-end;
  }

  &__likes {
    font-weight: 400;
    margin-right: 10px;
    font-size: 1.25rem;
    display: inline-flex;
    align-items: center;
  }
}
