.deckinfo {
  // max-width: min(95vw, 650px);
  padding: var(--section-paddingY) 0;
  width: min(1080px, 95vw);

  &__top {
    align-items: center;
    text-align: left;
    margin: 25px 0;
    background-color: var(--card-color);
    padding: 20px;
    border-radius: 10px;
    max-width: min(1080px, 95vw);
    // transition: ease;
  }

  &__title {
    font-size: 1.75rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 5px;
  }
  &__description {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 5px;
  }
  ul {
    // tags list, horizontal with menu-color background, fully rounded, clickable
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li {
      margin: 5px 5px;
      padding: 5px 10px;
      background-color: var(--background-color);
      border-radius: 20px;
      font-size: 0.8rem;
      font-weight: 600;
      cursor: pointer;
      transition: transform 0.2s ease;
      &:hover {
        transform: translateY(-2px);
      }
    }
  }
  &__icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 4px;
    transition: all 0.3s ease; //TODO: Why is author icon transitioning twice (overlap)?
  }

  &__author,
  &__created,
  &__rating,
  &__numcards,
  &__numusers {
    display: inline-flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 400;
    margin: 0 5px;
    margin-bottom: 10px;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    margin-right: 5px;
  }

  &__button:last-child {
    margin-right: 0;
  }

  &__cards {
    margin: 10px 0;

    &__title {
      margin: 0;
      text-align: left;
      font-size: 1.75rem;
      padding: 0 10px;
    }
  }
}
