.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(1080px, 95vw);
  min-height: min(600px, 100vh);
  padding: var(--section-paddingY) 0;

  &__title {
    margin: 0;
    margin-bottom: 35px;
  }

  &__title__subtitle {
    margin: 16px 0;
    font-size: 1.25em;
    font-weight: 600;
    letter-spacing: 0.05em;
  }

  p {
    margin: 16px 0;
    font-size: 1.15rem;
    text-align: justify;
    width: 100%;
  }

  &__section {
    display: flex;
    flex-direction: column;
  }

  &__graph {
    max-width: min(90vw, 320px);
    max-height: min(90vw, 320px);
    margin-bottom: 25px;
    transition: all 0.3s ease;

    &__label {
      margin: 10px 0;
      font-size: 1.1rem;
      font-weight: 400;
      letter-spacing: 0.015rem;
    }
  }

  &__graphs {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .about {
    &__graphs {
      flex-direction: row;
    }
    &__graph {
      margin: 0 15px;
    }
  }
}
