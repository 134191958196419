.study {
  padding: var(--section-paddingY) 0;
  width: min(1080px, 95vw);
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin: 0;
    margin-bottom: 35px;
    font-size: 1.1em;
  }

  &__cards {
    margin-top: 25px;
  }

  &__cardcount {
    margin-top: 20px;
    font-size: 1.4rem;
  }

  &__buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
  }
}

.swiper {
  max-width: min(90vw, 600px);
  padding: 25px 0;
  margin: 0;
}

@media screen and (min-width: 525px) {
  div.swiper-button-prev,
  div.swiper-button-next {
    display: block;
  }
  .swiper {
    padding: 0 50px;
    max-width: min(85vw, 600px);
  }
}
