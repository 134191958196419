.footer {
  width: 100vw;
  color: var(--menu-font);
  background-color: var(--menu-color);
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 6px var(--shadow-color);
  padding: 1rem 0;
  margin-top: auto;

  h1 {
    font-size: 2.5rem;
    font-weight: 400;
    font-family: var(--logo-font);
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 400;
    font-style: italic;
    margin: 0;
    padding: 0;
  }

  &__infolinks {
    margin: 0.5rem 0;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0.25rem 0;
  }

  a {
    color: var(--highlight-color);
  }
}
