.wrapper {
  h2 {
    margin: 0;
    margin-bottom: 100px;
  }
  padding-bottom: 100px;
}

.preview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: min(var(--page-width), 95vw);
  //   overflow: hidden;
  //   min-height: min(100vh, 1000px);
  min-height: min(500px, 100vh);
  padding: var(--section-paddingY) 0;
}
.swiper {
  max-width: min(var(--page-width), 95vw);
  padding-bottom: 35px;

  &-slide {
    width: auto !important;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__arrow {
    display: none; //only show on desktop
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

@media screen and (min-width: 1024px) and (hover: hover) {
  .swiper {
    max-width: calc(min(var(--page-width), 95vw) - 130px);

    &__arrow {
      display: block;
    }
  }
}
