.cardcreation {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 20px;
    margin-bottom: 20px;
    margin-right: 0;
    border-radius: 10px;
    background-color: var(--card-color);
  }

  &__input {
    margin: 0;
    padding: 10px;
    border-radius: 4px;
    width: 100%;
    background-color: var(--background-color);
    color: var(--text-color);
    border: none;
    box-sizing: border-box;
    font-size: 1rem;
    &__label {
      display: block;
      margin-bottom: 4px;
      margin-top: 8px;
      padding: 0;
      font-size: 1rem;
      font-weight: 400;
      text-align: left;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  &__preview {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .cardcreation {
    flex-direction: row;

    &__form {
      margin-right: 20px;
      margin-bottom: 0;
      width: 100%;
    }
  }
}
