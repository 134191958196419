.dropdown {
  display: flex;
  justify-content: flex-end;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: 53px;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
    background-color: var(--card-color);
    border: 2px solid var(--text-color);

    &__menu {
      background-color: var(--menu-color);
      border: 2px solid var(--menu-font);
      margin-right: 10px;
      align-items: flex-end;
    }
  }

  &__item {
    margin: 10px 0;
  }
}
