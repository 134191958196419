.deckpreview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin: 25px 0;
  background-color: var(--card-color);
  padding: 20px;
  border-radius: 10px;
  max-width: 95vw;
  transition: ease;

  &__left {
    width: 100%;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 5px;

    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  &__description {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 5px;

    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  ul {
    // tags list, horizontal with menu-color background, fully rounded, clickable
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li {
      margin: 5px 5px;
      padding: 5px 10px;
      background-color: var(--background-color);
      border-radius: 20px;
      font-size: 0.8rem;
      font-weight: 600;
      cursor: pointer;
      transition: transform 0.2s ease;
      &:hover {
        transform: translateY(-2px);
      }
    }
  }
  &__icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 4px;
    transition: all 0.3s ease; //TODO: Why is author icon transitioning twice (overlap)?
  }

  &__author,
  &__rating,
  &__numcards,
  &__numusers {
    display: inline-flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 400;
    margin: 0 5px;
    margin-bottom: 10px;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    margin-right: 5px;
  }
}

.swiper {
  width: min(90vw, 400px);
  padding: 40px 0;
  padding-top: 25px;
  margin: 0;
  div.swiper-button-prev,
  div.swiper-button-next {
    display: none; //TODO: Make this work
  }
}

@media screen and (min-width: 525px) {
  div.swiper-button-prev,
  div.swiper-button-next {
    display: block;
  }
  .swiper {
    padding: 40px 50px;
    padding-top: 25px;
  }
}

@media screen and (min-width: 1024px) {
  .deckpreview {
    flex-direction: row;
    max-width: 100%;

    &__left {
      padding: 0 20px;
    }

    &__right {
      //   padding-left: 20px;
      border-left: 2px solid var(--background-color);
    }
  }
  .nopreview {
    max-width: 425px;
    margin: 25px 10px;

    .deckpreview__left {
      padding: 0;
    }
  }
}
