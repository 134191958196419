.navbar {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 64px;
  background-color: var(--menu-color);
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--menu-font);
  user-select: none;

  &__list {
    display: none;
    // justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    margin: 0;
    list-style: none;
  }

  &__item {
    margin: 8px 10px;
    display: inline-block;
    position: relative;
    // height: 100%;
    // border-bottom: 3px solid var(--menu-font);
    display: flex;
    align-items: center;
  }

  &__link {
    color: var(--menu-font);
    text-decoration: none;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
    cursor: pointer;

    &:hover {
      color: var(--highlight-color);
    }
  }

  &__themetoggle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    padding-right: 8px;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: var(--menu-font);
      transition: color 0.3s ease-in-out;
    }

    &__wrapper {
      &:hover {
        .navbar__themetoggle {
          svg {
            color: var(--highlight-color);
          }
        }
        .navbar__link {
          color: var(--highlight-color);
        }
      }
    }
  }
}

.usericon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  padding: 0;
  padding-right: 8px;
}

.hamburgerbutton {
  margin: 0 12px;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--menu-color);
  transition: background-color 0.3s ease-in-out;
  position: fixed;
  right: 0;
  min-width: 160px;
  z-index: 99;
  user-select: none;

  &__list {
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 10px 20px;
    margin: 0;
    list-style: none;
    // position: relative;
    border: 2px solid var(--menu-font);
  }

  &__item {
    margin: 10px 0;
    display: inline-block;
    // position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .navbar__link {
      flex-direction: row;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    border-top: 2px solid var(--menu-divider);
    margin-top: 5px;
    padding-top: 5px;

    .hamburger__item {
      justify-content: flex-start;
    }
  }
}

.logo {
  font-family: var(--logo-font);
  font-size: 2.25rem;
  color: var(--menu-font);
  margin: 0 20px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 1024px) {
  .hamburger,
  .hamburgerbutton {
    display: none;
  }
  .navbar {
    &__list {
      display: flex;
    }

    &__themetoggle {
      padding-right: 0;
      padding-left: 8px;
    }
  }
  .usericon {
    padding-right: 0;
    padding-left: 8px;
  }
}
